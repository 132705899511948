<template>
  <div v-if="packages && packages.length">
    <div class="text-body-2 font-weight-bold text-uppercase">
      {{ $t("checkout.packageAddTitle") }}
    </div>

    <v-radio-group class="w-100" mandatory v-model="packagingTypeId" row>
      <v-radio
        v-for="pck in packages"
        :key="pck.productId"
        :value="'' + pck.productId"
        color="default"
      >
        <template v-slot:label>
          <div class="d-flex align-center flex-column flex-sm-row">
            <img
              :src="`/img_layout/packaging_type/${pck.productId}.png`"
              :alt="pck.name"
            />
            <div class="packaging-descr">
              <span class="d-block font-weight-bold text-body-2 mb-2">{{
                pck.shortDescr
              }}</span>
              <span class="d-block text-caption">{{ pck.description }}</span>
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import cartService from "~/service/cartService";
import { mapCartInfo } from "~/service/ebsn";

export default {
  name: "PackagesTypeList",
  data() {
    return { packages: [] };
  },
  computed: {
    ...mapCartInfo({
      packagingTypeId: "package_type"
    })
  },
  methods: {
    async fetchPackages() {
      this.packages = await cartService.getPackages();
    }
  },
  mounted() {
    this.fetchPackages();
  }
};
</script>
