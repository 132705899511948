<template>
  <div class="payment-check-user-data">
    <v-form
      v-if="userData"
      ref="userDataForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="updateUserData"
    >
      <v-row>
        <v-col cols="12" sm="5" class="pb-0">
          <v-text-field
            v-model="userData.email"
            :rules="emailRules"
            :label="$t('payment.block.2.userData.email')"
            required
            clearable
            dense
            outlined
            flat
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="5" class="pb-0">
          <v-text-field
            v-model="userData.phone"
            :rules="phoneRules"
            :label="$t('payment.block.2.userData.phone')"
            required
            clearable
            dense
            outlined
            flat
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" class="pb-0">
          <v-btn
            block
            type="submit"
            height="40"
            color="primary"
            depressed
            :disabled="!valid"
          >
            {{ $t("payment.block.2.userData.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import {
  isEmail,
  isGenericPhone,
  requiredValue
} from "~/validator/validationRules";

import cartInfo from "~/mixins/cartInfo";
import recaptcha from "~/mixins/recaptcha";

import tigrosRegistrationService from "@/service/tigrosRegistrationService";

import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";

export default {
  name: "PaymentCheckUserData",
  mixins: [cartInfo, recaptcha],
  data() {
    return {
      valid: false,
      userData: null,
      emailRules: [requiredValue(), isEmail()],
      phoneRules: [requiredValue(), isGenericPhone()]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    async updateUserData() {
      console.log("2");
      let res = await tigrosRegistrationService.updateUserDetail(this.userData);
      if (res) {
        console.log("updateUserData", res);
      }
    }
  },
  mounted() {
    this.userData = cloneDeep(this.user);
  }
};
</script>
