<template>
  <div class="payment-check-user-data">
    <v-form
      ref="mail2Form"
      v-model="valid"
      lazy-validation
      @submit.prevent="updateContact"
      v-if="userData.contact"
    >
      <v-row>
        <v-col cols="12 " sm="5" class="pb-0">
          <v-checkbox
            v-model="wantEmail2"
            :true-value="true"
            :false-value="false"
            class="want-email-checkbox mt-0 pb-6"
            hide-details
            required
            @click="toggleWantEmail2(wantEmail2)"
          >
            <template v-slot:label>
              <div class="d-flex flex-column default--text">
                <i18n path="payment.block.2.email2.checkboxLabel" tag="div">
                </i18n>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" sm="5" class="pb-0" v-show="wantEmail2">
          <v-text-field
            v-model="userData.contact.email2"
            :rules="emailRules"
            :label="$t('payment.block.2.email2.additionalEmail')"
            required
            clearable
            dense
            outlined
            flat
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" class="pb-0" v-show="wantEmail2">
          <v-btn block type="submit" height="40" color="primary" depressed>
            {{ $t("payment.block.2.email2.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { isEmail } from "~/validator/validationRules";

import cartInfo from "~/mixins/cartInfo";

import UserService from "@/commons/service/userService";

import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";

export default {
  name: "PaymentCheckUserData",
  mixins: [cartInfo],
  data() {
    return {
      valid: false,
      wantEmail2: false,
      userData: null,
      emailRules: [isEmail()]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    async updateContact() {
      await UserService.updateContact(this.userData);
    },
    toggleWantEmail2(wantEmail2) {
      if (wantEmail2 == false) {
        // if checkbox want email2 is off -> reset email2
        if (this.userData.contact) this.userData.contact.email2 = "";
        this.updateContact();
      }
    }
  },
  created() {
    this.userData = cloneDeep(this.user);
  }
};
</script>
